import { useDispatch, useSelector } from 'react-redux'
import 'react-loading-skeleton/dist/skeleton.css'
import React, { useEffect, useState } from 'react'
import { eventEntity, eventRegistrationEntity } from './store/root'
import { filled, usePrevious, t, blank } from '@/components/common/Helpers'
import { FormProvider, useForm, useFieldArray } from 'react-hook-form'
import { isString } from 'lodash'
import Notice from '@/components/common/ui/Notice'
import Button from '@/components/common/ui/buttons/Button'
import { PulseLoader } from 'react-spinners'
import LaravelErrorOutput from '@/components/common/ui/forms/LaravelErrorOutput'
import Input from '@/components/common/ui/forms/Input'
import Select from '@/components/common/ui/forms/Select'
import collect from 'collect.js'
import ModalStandalone from '@/components/common/ui/ModalStandalone'
import { PaymentIntentTypes } from '@/components/common/enum/PaymentIntentTypes'

export default function App({ data }) {

    const dispatch = useDispatch()

    const {
        event,
        eventFetchByIdLoading,
    } = useSelector(state => state.eventReducer)

    const {
        eventRegistration,
        eventRegistrationFetchByIdLoading,
        eventRegistrationCreateLoading,
        eventRegistrationCreateError,
    } = useSelector(state => state.eventRegistrationReducer)

    const previous = usePrevious({
        event,
        eventFetchByIdLoading,
        eventRegistrationFetchByIdLoading,
        eventRegistrationCreateLoading,
        eventRegistrationCreateError
    })

    const [success, setSuccess] = useState(null)
    const [completed, setCompleted] = useState(false)
    const [registering, setRegistering] = useState(false)
    const [errors, setErrors] = useState(null)
    const [modalOpen, setModalOpen] = useState(false)
    const [isFull, setIsFull] = useState(false)
    const [isPast, setIsPast] = useState(false)
    const [orgUuid, setOrgUuid] = useState(null)

    const loading = eventFetchByIdLoading || eventRegistrationCreateLoading || eventRegistrationFetchByIdLoading

    useEffect(() => {
        if (event && event.uuid != previous?.event?.uuid) {
            const query = new URLSearchParams(window.location.search)
            if (query.has('rsvp') || query.has('auto')) {
                openSingle()
            }
        }
    }, [event, previous])

    useEffect(async () => {
        const query = new URLSearchParams(window.location.search)
        if (query.has('auto')) {
            startRegistration()
        }
    }, [])

    const methods = useForm({
        defaultValues: {
            first_name: null,
            last_name: null,
            phone: null,
            email: null,
            ticket_count: 1,
            ticket_holders: []
        }
    })
    const { control } = methods
    const { fields, append, remove } = useFieldArray({
        control,
        name: 'ticket_holders'
    })

    const register = (params) => {
        // Add first and last name of person submitting the form as a ticket holder
        params.ticket_holders.unshift({
            first_name: params.first_name,
            last_name: params.last_name
        });
        const req = {
            ...params, ...{
                currency: 'USD',
                type: PaymentIntentTypes.EventRegistration.Value,
                organization_uuid: orgUuid
            }
        }
        setErrors(null)
        dispatch(eventRegistrationEntity.services.create(req, { params: { event: event.uuid } }))
    }

    useEffect(() => {
        if (!eventRegistrationCreateLoading && previous?.eventRegistrationCreateLoading && blank(eventRegistrationCreateError)) {
            setSuccess(true)
        }
    }, [eventRegistrationCreateLoading])

    useEffect(() => {
        if (filled(eventRegistrationCreateError)) {
            setErrors(eventRegistrationCreateError)
        }
    }, [eventRegistrationCreateError])

    const appearance = {
        theme: 'none',
        rules: {
            '.Input': {
                borderBottom: '1px solid #ccc',
                backgroundColor: 'rgb(249 250 251)',
                borderRadius: '0px',
                paddingTop: '0.5rem',
                paddingRight: '0.75rem',
                paddingBottom: '0.5rem',
                paddingLeft: '0.75rem',
                fontSize: '1rem',
                lineHeight: '1.5rem',
                marginBottom: '1px',
            },
            '.Input:focus': {
                borderBottom: '2px solid #2563E9',
                marginBottom: '0px',
                outline: '0px',
            },
        },
    }

    const startRegistration = () => {
        setRegistering(true)
        setSuccess(false)
        setCompleted(false)
        dispatch(eventEntity.services.findById(data.getAttribute('data-event-uuid')))
        setOrgUuid(data.getAttribute('data-organization-id'))
    }

    useEffect(() => {
        if (event && !previous?.event && registering) {
            openSingle()
        }
    }, [event, previous])

    const openSingle = () => {
        setModalOpen(true)
    }

    const clickClose = () => {
        if (success) {
            setCompleted(true)
        }
        dispatch(eventEntity.services.clearEntity())
        dispatch(eventRegistrationEntity.services.clearEntity())
        setSuccess(false)
        setModalOpen(false)
        setRegistering(false)
    }

    const ticketCount = methods.watch('ticket_count', '1') // Watch the ticket_count field

    useEffect(() => {
        const count = parseInt(ticketCount, 10) || 1
        const currentLength = fields.length
        if (count > currentLength + 1) {
            for (let i = currentLength; i < count - 1; i++) {
                append({ first_name: '', last_name: '' })
            }
        } else if (count < currentLength + 1) {
            for (let i = currentLength; i >= count - 1; i--) {
                remove(i)
            }
        }
    }, [ticketCount])

    const renderTicketHolderFields = () => {
        return fields.map((field, index) => (
            <div key={field.id} className="mb-6 grid gap-4 grid-cols-1 sm:grid-cols-2">
                <div>
                    <Input submitErrors={errors} label={`RSVP ${index + 2}: ${t.trans('forms.first-name')}`} name={`ticket_holders[${index}].first_name`} required />
                </div>
                <div>
                    <Input submitErrors={errors} label={`RSVP ${index + 2}: ${t.trans('forms.last-name')}`} name={`ticket_holders[${index}].last_name`} required />
                </div>
            </div>
        ))
    }

    useEffect(() => {
        if (data.getAttribute('data-event-full') == 1) {
            setIsFull(true)
        } else {
            setIsFull(false)
        }

        if (data.getAttribute('data-event-past') == 1) {
            setIsPast(true)
        }
    }, [])

    useEffect(() => {
        return () => {
            dispatch(eventEntity.services.clearEntity())
            dispatch(eventRegistrationEntity.services.clearEntity())
        }
    }, [])

    const maintenanceMessage = () => {
        return (
            <div className="p-6 mx-auto text-center">
                <Notice type="warning" title={t.trans('event.unavailable')}></Notice>
                <p className="mt-4 text-gray-700">{t.trans('event.unavailable-msg')}</p>
                <Button
                    onClick={clickClose}
                    label="Close"
                    className="mt-6 bg-blue-500 text-white px-4 py-2 rounded hover:bg-blue-600"
                />
            </div>
        )
    }

    const body = () => {
        return (
            <>
                {success ? (
                    <div>
                        <Notice type="success" title={t.trans('event.processed')} className="mb-4" />
                        <p className={'mt-2'}>
                            <Button onClick={clickClose} label={t.trans('event.close')} />
                        </p>
                    </div>
                ) : (
                    <>
                        <div className="flex gap-6">
                            <div className="flex-1">
                                {loading ? (
                                    <div className='w-full flex justify-center items-center'>
                                        <PulseLoader color={'#bebebe'} size={13} margin={10} />
                                    </div>
                                ) : (
                                    <FormProvider {...methods}>
                                        <form id="org-event-form" onSubmit={methods.handleSubmit(register)}>
                                            {
                                                filled(errors)
                                                &&
                                                (
                                                    <>
                                                        {isString(errors) ? (
                                                            <Notice className={'mb-4'} description={errors}
                                                                type={'warning'} />
                                                        ) : (
                                                            <LaravelErrorOutput name={'err'}
                                                                submitErrors={isString(errors) ? errors : errors.message}
                                                                nameOnly={false}
                                                                className={'mb-4'} />
                                                        )}
                                                    </>
                                                )
                                            }
                                            <div>
                                                {event && (
                                                    <>
                                                        {event?.ticket_options && (
                                                            <Input type="hidden" name={'ticket_option_id'}
                                                                defaultValue={collect(event.ticket_options).first().id} />
                                                        )}
                                                    </>
                                                )}
                                                <div className="mb-6 grid gap-4 grid-cols-1 sm:grid-cols-2">
                                                    <div>
                                                        <Input submitErrors={errors}
                                                            label={t.trans('forms.first-name')}
                                                            name="first_name" required />
                                                    </div>

                                                    <div>
                                                        <Input submitErrors={errors}
                                                            label={t.trans('forms.last-name')}
                                                            name="last_name" required />
                                                    </div>
                                                </div>
                                                <div className="mb-6 grid gap-4 grid-cols-1 sm:grid-cols-2">
                                                    <div>
                                                        <Input submitErrors={errors} type="email"
                                                            label={t.trans('forms.email')}
                                                            name="email" required />
                                                    </div>

                                                    <div>
                                                        <Input submitErrors={errors} type="tel"
                                                            label={t.trans('forms.phone')}
                                                            name="phone" required />
                                                    </div>
                                                </div>

                                                <div className="mb-6 grid gap-4 grid-cols-1 sm:grid-cols-2">
                                                    <div>
                                                        <Select defaultValue={'1'}
                                                            label={t.trans('event.number')}
                                                            options={['1', '2', '3', '4', '5', '6', '7', '8', '9', '10']}
                                                            name="ticket_count" required />
                                                    </div>

                                                </div>

                                                {renderTicketHolderFields()}

                                                <div>
                                                    <button type="submit"
                                                        className="px-5 py-2 border border-transparent
                                                                font-medium rounded-full shadow-sm text-white bg-cta-green hover:bg-cta-green-500
                                                                focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-yellow-400">
                                                        {t.trans('event.complete')}
                                                    </button>
                                                </div>

                                            </div>
                                        </form>
                                    </FormProvider>
                                )}
                            </div>
                        </div>
                    </>
                )}
            </>
        )
    }

    return (
        <>
            {loading ? (
                <div className='w-full flex justify-center items-center'>
                    <PulseLoader color={'#bebebe'} size={8} margin={8.5} />
                </div>
            ) : (
                <>
                    {!isFull && !isPast && !completed && (
                        <div onClick={startRegistration}
                            className="flex flex-1 space-x-2 ">
                            <button
                                className="rsvp flex-1 inline-flex items-center justify-center text-center"
                            >{'RSVP'}</button>
                        </div>
                    )}

                    {isFull && !isPast && !completed && (
                        <div className="flex flex-1 h-10 space-x-2 pt-1 justify-items-end">
                            <span
                                className="disabled rsvp flex-1 bg-gray-300 inline-flex items-center justify-center text-center hover:cursor-not-allowed"
                            >{t.trans('event.full')}</span>
                        </div>
                    )}

                    {completed && (
                        <div>{t.trans('event.registered')}</div>
                    )}

                    <ModalStandalone
                        cancelButton={false}
                        Text={event?.app_in_maintenance_mode ? maintenanceMessage : body}
                        open={modalOpen}
                        onCancel={clickClose}
                        onClose={clickClose}
                    />
                </>
            )}
        </>
    )

}
