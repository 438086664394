import { configureStore } from '@reduxjs/toolkit'
import { createManagedResource } from '@/vendor/react-relaxful/relax/resourceCreator'

const api_url = `${import.meta.env.VITE_APP_URL}/api/public`

/**
 * Registry of all managed resource stores
 */

export const eventEntity = createManagedResource('event', 'events', api_url)
export const eventRegistrationEntity = createManagedResource('eventRegistration', 'events/{event}/register', api_url)

/**
 * Root Redux Store
 */
export const store = configureStore({
    reducer: {
        eventReducer: eventEntity.reducer,
        eventRegistrationReducer: eventRegistrationEntity.reducer,
    },
    middleware: (getDefaultMiddleware) => getDefaultMiddleware({
        serializableCheck: false
    }),
    devTools: import.meta.env.VITE_APP_ENV !== 'production',
})
