import React from 'react'
import ReactDOM from 'react-dom'
import {Provider} from 'react-redux'
import {store} from './store/root'
import ErrorBoundary from '../components/common/ErrorBoundary'
import App from './App'

const app = document.getElementsByClassName('obituary-service-event')

if (app) {
    Array.from(app).forEach(el => {
        ReactDOM.render(
            <ErrorBoundary>
                <Provider store={store}>
                    <App data={el} />
                </Provider>
            </ErrorBoundary>,
            el
        )
    })
}

